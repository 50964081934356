interface LayoutProps {
  children: React.ReactNode;
  headerContent?: React.ReactNode;
  footerContent?: React.ReactNode;
  solidFooter?: boolean;
}

export default function Layout({
  children,
  headerContent,
  footerContent,
  solidFooter = false,
}: LayoutProps) {
  return (
    <div className="flex flex-col h-full w-full max-w-[430px] mx-auto">
      <header className="relative">{headerContent}</header>

      <main className="w-full h-full overflow-auto">{children}</main>

      {footerContent && (
        <footer
          className={`fixed bottom-0 left-0 right-0 py-5 max-w-[430px] mx-auto ${solidFooter ? "bg-white" : "transparent"}`}
        >
          <div className="px-4">{footerContent}</div>
        </footer>
      )}
    </div>
  );
}
